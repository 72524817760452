// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function setupHeaderSubmenu() {
  var header_submenu = $('.menu-item-has-children');

  header_submenu.hover(
    function () {
      // On mouse enter, slide the `.submenu` down
      $(this)
        .find('.submenu')
        .stop(true, true)
        .slideDown(300)
        .addClass('submenu-active');

      // Animate padding-right for `.menu-main-menu-container`
      $('.menu-main-menu-container').stop(true, true).animate(
        {
          paddingRight: '27.5rem',
        },
        300
      );
    },
    function () {
      // On mouse leave, slide the `.submenu` up
      $(this)
        .find('.submenu')
        .stop(true, true)
        .slideUp(300)
        .removeClass('submenu-active');

      // Reset padding-right for `.menu-main-menu-container`
      $('.menu-main-menu-container').stop(true, true).animate(
        {
          paddingRight: '7.5rem',
        },
        300
      );
    }
  );
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  const svgMarkup = `
    <svg xmlns='http://www.w3.org/2000/svg' width='14.862' height='24.82' viewBox='0 0 14.862 24.82' class='custom-svg'>
        <g id='Group_301' data-name='Group 301' transform='translate(-1009.83 968.82) rotate(-90)'>
            <path id='arrrow_black' data-name='arrrow black' d='M7.431,24.82H4.609L0,12.664,4.086,0H6.908L2.822,12.664Z' transform='translate(944 1017.261) rotate(-90)' fill='#8c643a'/>
            <path id='arrrow_black-2' data-name='arrrow black' d='M7.431,24.82H4.609L0,12.664,4.086,0H6.908L2.822,12.664Z' transform='translate(944 1024.692) rotate(-90)' fill='#8c643a'/>
        </g>
    </svg>
`;

  $('.menu-item-has-children').each(function () {
    $(this).append(svgMarkup);
    var subBtn = $(this).find('.custom-svg');

    subBtn.click(function () {
      var parentItem = $(this).parent(); // Get the parent element
      var submenu = parentItem.find('.submenu');

      if (submenu.is(':visible')) {
        // If submenu is visible, slide it up and remove the 'active' class
        submenu.stop(true, true).slideUp(300).removeClass('submenu-active');
        parentItem.removeClass('menu-active');
      } else {
        // If submenu is hidden, slide it down and add the 'active' class
        submenu.stop(true, true).slideDown(300).addClass('submenu-active');
        parentItem.addClass('menu-active');
      }
    });
  });

  // Add click event to the appended SVG

  const repeater = document.querySelector('.client-repeater');
  if (repeater) {
    repeater.scrollTop = 0; // Start at the top

    setInterval(() => {
      repeater.scrollTop += 1; // Scroll down one pixel at a time
      if (repeater.scrollTop >= repeater.scrollHeight - repeater.clientHeight) {
        repeater.scrollTop = 0; // Reset to top when at the bottom
      }
    }, 50); // Adjust speed by changing the interval (lower = faster)
  }
  $('.gfield_select').select2({
    placeholder: 'Select an option',
    allowClear: true,
    minimumResultsForSearch: 5, // Show search only if there are 5 or more options
  });

  $(document).on('focus', '.select2.select2-container', function (e) {
    // only open on original attempt - close focus event should not fire open
    if (
      e.originalEvent &&
      $(this).find('.select2-selection--single').length > 0
    ) {
      $(this).siblings('select').select2('open');
    }
  });
  $('.footer-btn').click(function () {
    if ($('.footer-form-js').is(':visible')) {
      $('.footer-form-js').slideUp();
    } else {
      $('.footer-form-js').slideDown();
    }
  });
  $('.footer-contact__form-btn svg').click(function () {
    $('.footer-form-js').slideUp();
  });
  $('.footer-contact__form-btn svg').trigger('click');

  function setupHeaderSubmenu() {
    var header_submenu = $('.menu-item-has-children');

    // Mouse enter event
    header_submenu.on('mouseenter', function () {
      // Slide the `.submenu` down
      $(this)
        .find('.submenu')
        .stop(true, true)
        .slideDown(300)
        .addClass('submenu-active');

      // Animate padding-right for `.menu-main-menu-container` if window width is 1024px or greater
      if ($(window).width() >= 1024) {
        $('.menu-main-menu-container').stop(true, true).animate(
          {
            paddingRight: '27.5rem',
          },
          300
        );
      }
    });

    // Mouse leave event
    header_submenu.on('mouseleave', function () {
      // Slide the `.submenu` up
      $(this)
        .find('.submenu')
        .stop(true, true)
        .slideUp(300)
        .removeClass('submenu-active');

      // Reset padding-right for `.menu-main-menu-container` if window width is 1024px or greater
      if ($(window).width() >= 1024) {
        $('.menu-main-menu-container').stop(true, true).animate(
          {
            paddingRight: '7.5rem',
          },
          300
        );
      }
    });

    // Re-evaluate the submenu animation logic on window resize
    $(window).on('resize', function () {
      if ($(window).width() < 1024) {
        $('.menu-main-menu-container')
          .stop(true, true)
          .css('paddingRight', '0');
      }
    });
  }

  $(window).on('resize', function () {
    if ($(window).width() > 1024) {
      setupHeaderSubmenu();
    }
  });

  if ($(window).width() > 1024) {
    setupHeaderSubmenu();
  }

  $('.team-preview__slider').slick({
    dots: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: $('.team-button-prev'), // Custom prev button
    nextArrow: $('.team-button-next'), // Custom next button
    infinite: false, // Disable infinite scroll
    asNavFor: '.team-preview-image__slider',
  });
  $('.team-preview-image__slider').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.team-preview__slider',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
  });
  $('.team-button-prev').hide();
  // Add event listener for slide change
  $('.team-preview__slider').on(
    'afterChange',
    function (event, slick, currentSlide) {
      // Check total slides using slick object
      const totalSlides = slick.slideCount;

      // Show/Hide the Prev button
      if (currentSlide === 0) {
        $('.team-button-prev').hide(); // Hide prev button on the first slide
      } else {
        $('.team-button-prev').show(); // Show prev button otherwise
      }

      // Show/Hide the Next button
      if (currentSlide === totalSlides - 1) {
        $('.team-button-next').hide(); // Hide next button on the last slide
      } else {
        $('.team-button-next').show(); // Show next button otherwise
      }
    }
  );

  // Trigger visibility update after initialization
  $('.team-preview__slider').on('init', function (event, slick) {
    const currentSlide = slick.currentSlide;
    const totalSlides = slick.slideCount;

    // Set initial visibility for Prev button
    if (currentSlide === 0) {
      $('.team-button-prev').hide();
    }

    // Set initial visibility for Next button
    if (currentSlide === totalSlides - 1) {
      $('.team-button-next').hide();
    }
  });

  let open = false;

  // Function to check and update the state of the button
  function updateButtonState() {
    if ($('.top-bar').css('display') !== 'block') {
      $('.title-bar__btn').removeClass('close');

      open = false;
    } else {
      $('.title-bar__btn').addClass('close');

      open = true;
    }
  }

  // Initial state check
  updateButtonState();

  // Toggle the `close` class on button click
  $('.title-bar__btn').on('click', function (event) {
    event.preventDefault();

    if (!open) {
      $(this).addClass('close');
      open = true;
    } else {
      $(this).removeClass('close');
      open = false;
    }
  });
  // Listen for clicks on the entire document
  $(document).on('click', function (event) {
    if (open && $(event.target).closest('header').length === 0) {
      $('.title-bar__btn').trigger('click'); // Trigger the button click
    }
  });
  // Update the button state on window resize
  $(window).on('resize', function () {
    updateButtonState();
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1200) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  $('.slider-inner').each(function () {
    const categorySelector = $(this).find('.slider-categories');
    const contentSelector = $(this).find('.slider-content');

    if (categorySelector && contentSelector) {
      initializeSlickCarousels(categorySelector, contentSelector);
    }
  });

  $('.awards-slider').slick({
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: `
    <button type='button' class='slick-prev'>
      <svg xmlns='http://www.w3.org/2000/svg' width='27.223' height='38.142' viewBox='0 0 27.223 38.142'>
        <g id='Group_144' data-name='Group 144' transform='translate(0)'>
          <g id='Group_16' data-name='Group 16' transform='translate(0 38.142) rotate(-90)'>
            <path id='arrrow_black' data-name='arrrow black' d='M13.611,0H8.442L0,18.681,7.485,38.142h5.169L5.169,18.681Z' transform='translate(38.142 13.611) rotate(90)' fill='#e6e1dc'/>
            <path id='arrrow_black-2' data-name='arrrow black' d='M13.611,0H8.442L0,18.681,7.485,38.142h5.169L5.169,18.681Z' transform='translate(38.142 0) rotate(90)' fill='#e6e1dc'/>
          </g>
        </g>
      </svg>
    </button>
  `,
    nextArrow: `
    <button type='button' class='slick-next'>
     <svg xmlns='http://www.w3.org/2000/svg' width='27.222' height='38.142' viewBox='0 0 27.222 38.142'>
        <g id='Group_143' data-name='Group 143' transform='translate(-1554.687 -1798.539)'>
          <g id='Group_16' data-name='Group 16' transform='translate(1554.687 1836.681) rotate(-90)'>
            <path id='arrrow_black' data-name='arrrow black' d='M13.611,38.142H8.442L0,19.461,7.485,0h5.169L5.169,19.461Z' transform='translate(0 13.611) rotate(-90)' fill='#e6e1dc'/>
            <path id='arrrow_black-2' data-name='arrrow black' d='M13.611,38.142H8.442L0,19.461,7.485,0h5.169L5.169,19.461Z' transform='translate(0 27.222) rotate(-90)' fill='#e6e1dc'/>
          </g>
        </g>
      </svg>
    </button>
  `,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          centerMode: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: true,
          centerMode: true,
          centerPadding: '10px',
        },
      },
    ],
  });

  // jQuery code goes here
  // if ($(window).scrollTop() !== 0) {
  // $(window).scrollTop($(window).scrollTop() + 1);
  // }
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  const header = document.querySelector('header'); // Replace 'header' with your specific selector if needed
  if (window.scrollY > 0) {
    header.classList.add('header-active');
  } else {
    header.classList.remove('header-active');
  }
});

function initializeSlickCarousels(categorySelector, contentSelector) {
  const slickCarousel = $(categorySelector);
  const slickContent = $(contentSelector);

  slickCarousel.on('setPosition', function () {
    let maxHeight = 0;

    slickCarousel.find('.slider-categories__item').each(function () {
      $(this).height('');
    });

    slickCarousel.find('.slider-categories__item').each(function () {
      const slideHeight = $(this).outerHeight();
      if (slideHeight > maxHeight) {
        maxHeight = slideHeight;
      }
    });

    slickCarousel.find('.slider-categories__item').each(function () {
      $(this).height(maxHeight);
    });
  });

  // Initialize the category slider
  slickCarousel.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: contentSelector,
    centerMode: true,
    speed: 300,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 0,
    prevArrow:
      '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  // Initialize the content slider
  slickContent.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: categorySelector,
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
    fade: true,
    adaptiveHeight: true,
    rows: 0,
  });

  // Add mousewheel functionality for the category slider
  slickCarousel.on('mousewheel', function (e) {
    e.preventDefault();
    if (e.originalEvent.deltaY > 0) {
      slickCarousel.slick('slickNext');
    } else {
      slickCarousel.slick('slickPrev');
    }
  });
}
